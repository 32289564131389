import axios from 'axios'
// import { Toast } from 'vant'
import {setLocal} from '@/common/js/utils'
import router from '../router'
// import {showFailToast} from 'vant';
import 'vant//es/toast/style/index';
import {wechatLogin} from "@/service/user";
// axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? '' : 'https://api-eshop.vdvq.com/frontApis/shop/v1'
axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? '' : 'https://api-hualong.vdvq.com/frontApis/shop/v1'

axios.defaults.withCredentials = true
axios.defaults.headers['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers['Authorization'] = "Bearer " + localStorage.getItem('token') || ''
axios.defaults.headers.post['Content-Type'] = 'application/json'

axios.interceptors.response.use(res => {
    if (typeof res.data !== 'object') {
        // Toast.fail('服务端异常！')

        return Promise.reject(res)
    }
    if (res.data.code !== 200) {
        if (res.data.message) {
            // showFailToast(res.data.message)
            console.log(res.data.message)
        }
        if (res.data.code === 416 || res.data.code === 403) {
            // 截取url中的code方法
            var url = location.search;
            var theRequest = {};
            if (url.indexOf("?") !== -1) {
                var str = url.substr(1);
                var strs = str.split("&");
                for (var i = 0; i < strs.length; i++) {
                    theRequest[strs[i].split("=")[0]] = strs[i].split("=")[1];
                }
            }
            if (theRequest.code) {
                const {data} = wechatLogin({code: theRequest.code})
                setLocal('token', data.access_token)
                window.location.href = '/'
            }
            if (/(MicroMessenger)/i.test(navigator.userAgent)) {
                let appid = "wxb1079e08e023348b"; //微信APPid
                let local = "https://meshop.vdvq.com/";
                //打开地址进行授权
                window.location.href =
                    "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
                    appid +
                    "&redirect_uri=" +
                    encodeURIComponent(local) +
                    "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
            } else {
                router.push({path: '/login'})
            }
        }
        if (res.data.data && window.location.hash === '#/login') {
            setLocal('token', res.data.data)
            axios.defaults.headers['token'] = res.data.data
        }
        return Promise.reject(res.data)
    }

    return res.data
})

export default axios
